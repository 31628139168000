.image-property{
    object-fit: cover;
    width: 100%;
    height: auto;
    aspect-ratio: 4/3; 
}

.image-type{
    border: 2px solid #008080;
    border-radius: 10px;
}