/* @keyframes expandIcon {
    from {
        transform: scale(1);
    }

    to {
        transform: scale(1.15);
    }
} */

@keyframes expandMenu {
    0% {
        transform: scaleY(0%);
    }

    100% {
        transform: scaleY(100%);
    }
}

@keyframes collapseMenu {
    0% {
        transform: scaleY(100%);
    }

    100% {
        transform: scaleY(0%);
    }
}

@media screen and (min-width: 975px) {
    .NavBg {
        display: inline-block;
        background-color: #09060a;
        height: auto;
        top: 0;
        width: 100vw;
    }

    .NavButton {
        box-sizing: border-box;
        text-decoration: none !important;
        border: 0;
        width: 21%;
        background-color: #09060a;
        color: #FFFFFF !important;
        font-size: 2.75vh;
        /* margin-left: 8vw; */
        text-align: center;
        white-space: nowrap;
    }

    .NavButton:hover {
        cursor: pointer;
        color: #bdbdbd !important;
        text-decoration: none;
    }

    .NavButton:focus {
        color: #FFFFFF !important;
        text-decoration: none;
    }

    /* #LogoImg {
        height: 4vh;
        margin: 0;
        padding-bottom: 10%;
        padding-left: 25%;
        padding-bottom: 15%;
        position: relative;
    } */

    #LogoButton {
        /* height: 5.75vh;
        width: 4.75vh; */
        margin-block: 0.5vh;
        height: 8vmin;
        width: 7.5vmin;
        background-image: url('./Resources/LogoPic.png');
        background-size: contain;
        background-repeat: no-repeat;
        border: #09060a;
        padding: 0;
        margin-left: 1vw;
        /* height: 6.45vh; */
        /* padding-top: 0.2rem;
        padding-bottom: 0.5625rem; */
        position: relative;
        align-items: center;
        justify-content: center;
    }

    .ExpandButtons {
        display: none;
    }

    #HamburgerToggle {
        display: none;
    }

    #HamburgerMenu {
        display: none;
    }
}

@media screen and (max-width: 975px) {
    .NavBg {
        display: inline-block;
        background-color: #09060a;
        height: auto;
        top: 0;
        width: 100vw;
    }

    .NavButton {
        display: none;
    }

    #LogoButton {
        margin-block: 0.5vh;
        /* min-height: 4vh;
        min-width: 2.75vh; */
        height: 7vmax;
        width: 6.5vmax;
        background-image: url('./Resources/LogoPic.png');
        background-size: contain;
        background-repeat: no-repeat;
        border: #09060a;
        padding: 0;
        margin-left: 0.5rem;
        /* height: 6.45vh; */
        /* padding-top: 0.2rem;
        padding-bottom: 0.5625rem; */
        position: relative;
        align-items: center;
        justify-content: center;
    }

    #HamburgerToggle {
        text-align: center;
        background-color: #09060a;
        border: 0;
        color: #FFFFFF;
        padding: 0;
        margin-right: 1vw;
        font-size: 4vmax;
    }

    .ExpandButtons {
        background-color: #09060a;
        color: #FFFFFF;
        font-size: 2.75vmax;
        width: 100%;
        height: auto;
        padding: 1.25vh;
        margin: 0;
        text-align: left;
        text-decoration: none;
        border-left: 0;
        border-right: 0;
        border-bottom: 0;
        border-top: 0.025vh;
        border-style: solid;
        border-color: rgb(40, 40, 40);
    }

    .ExpandButtons:hover {
        cursor: pointer;
        color: #bdbdbd !important;
        text-decoration: none;
    }

    .ExpandButtons:focus {
        color: #FFFFFF;
        text-decoration: none;
    }

    .openHamburger {
        /* max-height: 38vh;
        transition: max-height 0.3s ease-in; */
        transform-origin: top center;
        animation-name: expandMenu;
        animation-duration: 0.3s;
        animation-fill-mode: forwards;
        animation-timing-function: ease-in;
        -webkit-animation-name: expandMenu;
        -webkit-animation-duration: 0.3s;
        -webkit-animation-fill-mode: forwards;
        -webkit-animation-timing-function: ease-in;
    }

    .closeHamburger {
        /* max-height: 0vh;
        transition: max-height 0.3s ease-out; */
        transform-origin: top center;
        animation-name: collapseMenu;
        animation-duration: 0.3s;
        animation-fill-mode: forwards;
        animation-timing-function: ease-out;
        -webkit-animation-name: collapseMenu;
        -webkit-animation-duration: 0.3s;
        -webkit-animation-fill-mode: forwards;
        -webkit-animation-timing-function: ease-out;
    }
}