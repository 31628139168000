.contain{
    max-width: 1200px;
    margin: 0 auto;
}
.box{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.background{
    border: 2px solid #008080;
    border-radius: 5px;
    background: linear-gradient(#00808060, #00808010);
}

.contentBox-heading{
    text-align: center;
    font-family: "xspace";
    font-size: 3.2rem;
    margin: 0;
    color: white;
}

.content{
    display: inline-flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
}


.image-box {
    flex: 2;
    

    margin-right: 7%;
    margin-left: 1%;
    margin-bottom: 5%;
}

.text-box{
    --font-min: 14px; 
    --font-max: 27px;
    

    height: 100%;

    flex: 1.5;
    text-align: justify;
    

    margin-left: 7%;
    margin-right: 1%;
    margin-bottom: 2.5%;

}
.textbox-content{
    /* font-family:'Times New Roman', Times, serif; */
    font-family: "Rockwell";
}

@media screen and (max-width: 679px ) {
    .content{
        flex-direction: column;
        gap: 0;
    }
    .image-box{
        margin-right: 5%;
        margin-left: 5%;
    }
    .text-box{
        margin-right: 5%;
        margin-left: 5%;
    }
    .text-box p{
        font-size: var(--font-min);
        line-height: normal;
    }
    .contentBox-heading{
        font-size: 2.5rem;
    }
    .seemore-scroll{
        font-size: 10px;
    }
}



@media screen and (min-width: 1201px) {
    .text-box p{
        font-size: var(--font-max);
        line-height: 1.3;
    }
    .contentBox-heading{
        font-size: 4rem;
    }
}
p{
    margin: 0;
    width: auto;
    font-size: 2vw;
    color: white;    
}
.Links{
    align-self: flex-start;
    padding-left: 15px;
    /* padding-bottom: 10px; */
    
}
.link-text-format{
    font-size: 15px;
}
.see-more-button{
    align-self: flex-start;
    margin-left: 7%;
    padding-bottom: 10px;
}
.see-more-text-format{
    font-size: 15px;
}
.seemore-scroll{
    text-decoration: none;
    color: #00D7D7;
    font-size: 1.5vw;
}

.seemore-scroll:hover {
    cursor: pointer;
    color: #006060;
}

.content-details-box{
    display: flex;
    flex-direction: column;
    gap: 20px;
}