@import url('https://fonts.googleapis.com/css2?family=Genos&display=swap');

.ContactBar {
    background-color: black;
    height: auto;
}

@media screen and (min-width: 975px) {
    .ContactUs {
        position: relative;
        background-color: black;
        height: 40vh;
    }

    .ContactHomeLogo {
        margin-right: 3vw;
    }

    .ColLinks {
        cursor: 'pointer';
    }

    .ContactWrapper {
        background-color: black;
        display: flex;
        flex-direction: row;
        -ms-flex-direction: row;
    }

    .ContactHeading {
        display: flex;
        flex-direction: row;
        -ms-flex-direction: row;
        width: 100%;
        margin-bottom: 2.5vh;
        font-family: "xspace";
    }

    .ContactTitle {
        font-size: 3.75vw;
    }

    .ContactColumn {
        background-color: black;
        width: 100%;
        /* padding-left: 6vw; */
        color: aliceblue;
        text-align: left;
        margin-right: auto;
    }

    .Col1 {
        display: flex;
        padding-left: 3vw;
    }

    .Col2, .Col3 {
        display: flex;
        flex-direction: column;
        -ms-flex-direction: column;
        justify-content: flex-start;
        row-gap: 3vh;
        padding-left: 3.75vw;
        margin-right: auto;
        padding-top: 0.5vmax;
    }
    
    .ContactAddress {
        font-size: 1.1vmax;
        padding-top: 0.3vh;
        color: aliceblue;
    }
}

@media screen and (max-width: 975px) and (min-width: 500px) {
    .ContactUs {
        position: relative;
        background-color: black;
        height: 50vh;
    }

    .ContactHomeLogo {
        flex-grow: 1;
    }

    .ColLinks {
        margin-inline: 'auto';
        cursor: 'pointer';
    }

    .ContactWrapper {
        background-color: black;
        display: flex;
        flex-direction: column;
        -ms-flex-direction: column;
    }

    .ContactHeading {
        display: flex;
        flex-direction: row;
        -ms-flex-direction: row;
        width: 100%;
        margin-bottom: 0vh;
        font-family: "xspace";
    }

    .ContactTitle {
        font-size: 4.5vh;
    }

    .ContactColumn {
        background-color: black;
        width: 100%;
        height: max-content;
        /* padding-left: 6vw; */
        color: aliceblue;
        text-align: center;
        /* margin-right: auto; */
    }

    .Col1 {
        display: flex;
        justify-content: center;
    }

    .Col2, .Col3 {
        display: flex;
        flex-direction: column;
        -ms-flex-direction: column;
        justify-content: space-evenly;
        font-size: 2vmin;
        /* justify-content: center; */
    }
    
    .ContactAddress {
        font-size: 2vmin;
        padding-top: 1vh;
        color: #f0f8ff;
        padding-bottom: 0 !important;
    }

    .AutoAlign {
        margin-inline: auto;
    }
}

@media screen and (max-width: 500px) {
    .ContactUs {
        position: relative;
        background-color: black;
        height: 50vh;
    }
    
    .ContactHomeLogo {
        flex-grow: 1;
    }

    .ColLinks {
        margin-inline: 'auto';
        cursor: 'pointer';
    }

    .ContactWrapper {
        background-color: #252525;
        display: flex;
        flex-direction: column;
        -ms-flex-direction: column;
    }

    .ContactHeading {
        display: flex;
        flex-direction: row;
        -ms-flex-direction: row;
        width: 100%;
        margin-bottom: 1.5vh;
        font-family: "xspace";
    }

    .ContactTitle {
        font-size: 3vh;
    }

    .ContactColumn {
        background-color: black;
        width: 100%;
        height: max-content;
        /* padding-left: 6vw; */
        color: aliceblue;
        text-align: center;
        /* margin-right: auto; */
    }

    .Col1 {
        display: flex;
        justify-content: center;
    }

    .Col2, .Col3 {
        display: flex;
        flex-direction: column;
        -ms-flex-direction: column;
        justify-content: space-evenly;
        font-size: 2.75vmin;
        justify-content: center;
    }
    
    .ContactAddress {
        font-size: 2.75vmin;
        padding-top: 1vh;
        color: aliceblue;
    }
    
    .AutoAlign {
        margin-inline: auto;
    }
}