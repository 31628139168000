.image-property{
    object-fit: cover;
    width: 45%;
    height: auto;
    aspect-ratio: 18/9; 
    max-width: 650px;
}

.image-type{
    border: 2px solid #008080;
    border-radius: 10px;
}

@media screen and (max-width: 679px){
    .image-property{
        width: 90%;
    }
    
}
