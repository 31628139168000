.AboutUsWrapper {
    display: block;
    width: 100vw;
    /* height: 500vh; */
    overflow-x: hidden;
    overflow-y: visible;
    justify-content: center;
    text-align: center;
}

@import url('https://fonts.googleapis.com/css2?family=Genos&display=swap');

.AboutUsWrapper {
    min-height: 100vh;
    /* scroll-snap-type: y proximity; */
}

.AbUsWholePage {
    /* scroll-snap-align: start;
    scroll-snap-stop: always; */
    margin-top: 30vh;
}

.Gallery {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    gap: 30px;
}

.GalleryHeading {
    color: #FFFFFF;
    font-family: "Xspace";
}

.GalleryExpandButton:hover {
    cursor: pointer;
}

.openExtendedGallery {
    transform-origin: top center;
    animation-name: expandMenu;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in;
    -webkit-animation-name: expandMenu;
    -webkit-animation-duration: 0.3s;
    -webkit-animation-fill-mode: forwards;
    -webkit-animation-timing-function: ease-in;
}

.closeExtendedGallery {
    transform-origin: top center;
    animation-name: collapseMenu;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in;
    -webkit-animation-name: collapseMenu;
    -webkit-animation-duration: 0.3s;
    -webkit-animation-fill-mode: forwards;
    -webkit-animation-timing-function: ease-in;
}

@media screen and (min-width: 600px) {
    .GalleryRow {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        margin-block: 2.75vh;
    }
}

@media screen and (max-width: 600px) {
    .GalleryRow {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        margin-block: 2.75vh;
    }
}

@media screen and (min-width: 675px) {
    .Max35 {
        max-width: 35vw;
    }

    .Max40 {
        max-width: 40vw;
    }
}

.Aboutuss {
    display: flex;
    flex-direction:     column;
    gap: 20px;
}

.previous-board-members {
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.ClickhereLinkLight,
.ClickhereLinkDark {
    font-size: 1.5vmax;
}

@media screen and (max-width: 679px) {

    .ClickhereLinkLight,
    .ClickhereLinkDark {
        font-size: 3.0vmax;
        padding-top: 2.5vw;
    }
}
/* .Address{
    margin-top: 4vw;
    z-index: -10;
} */
.boardmemberlist-lowermargin{
    padding: 20px;
}
#ExtendedGallery{
    margin-left: 10px;
    margin-right: 10px;
}