.member-designationbox{
    border-width: 4px 4px 2px 4px;
    border-style: solid;
    border-color: #008080;
    border-radius: 17px 17px 0 0;
    width: 100%;
    height: 5vw;
    background: linear-gradient(rgb(87, 121, 132, 0.79), rgb(87, 121, 132, 0));
}
.member-imageproperty{
    aspect-ratio: 35/50;
    width: 100%;
    height: auto;
    object-fit: cover;
    border-width: 2px 4px 2px 4px;
    border-style: solid;
    border-color: #008080;
}
.member-box{
    display: flex;
    flex-direction: column;
    gap: 0;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    transition: transform 0.3s ease-in-out;
    position: relative;
    max-width: 250px;
}
.member-box:hover{
    transform: scale(1.15);
}
.member-namebox{
    border-width: 2px 4px 4px 4px;
    border-style: solid;
    border-color: #008080;
    border-radius: 0 0 17px 17px;
    width: 100%;
    height: 5vw;
    background: linear-gradient(rgb(87, 121, 132, 0.79), rgb(87, 121, 132, 0));
}
.member-heading{
    text-align: center;
    font-family: 'Genos';
    font-size: 1.6vw;
    color: white;
    text-decoration: underline;
    text-decoration-color: transparent;
}
.member-name{
    text-align: center;
    font-family: 'Genos';
    font-size: 1.6vw;
    color: white;
    text-decoration: none;
}
.clickable{
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    position: absolute;
    z-index: 1;
}
.memberbox-margin{
    margin-left: 11px;
    margin-right: 11px;
}
@media screen and (max-width: 679px ) {
    .member-heading{
        font-size: 4.8vw;
    }
    .member-designationbox{
        height: 12vw;
    }
    .member-namebox{
        height: 11vw;
    }
    .member-name{
        font-size: 4.2vw;
    }
    .member-box{
        max-width: 200px;
    }
}