.EventsWrapper {
    display: block;
    width: 100vw;
    /* height: 500vh; */
    overflow-x: hidden;
    overflow-y: visible;
    justify-content: center;
    text-align: center;
}

.EventsWrapper {

    width: 100vw;
    height: 100vh;
    justify-content: center;
    text-align: center;
    bottom: 0;
    position: static;
    overflow-x: hidden;
    overflow-y: visible;
    /* scroll-snap-type: y proximity; */
}

.EventsContent {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

/* .WholePage {
    scroll-snap-align: start;
    scroll-snap-stop: always;
    padding-Top: '100px';
    padding-left: '6vw';
    padding-right: '6vw';
    height: '600px'; this part commented seperately
} */

.Events {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

/* .EventsWholePage{
    height: auto;
    scroll-snap-align: start;
    scroll-snap-stop: always;
    padding-Top: "10vh";
    padding-left: "6vw";
    padding-right: "6vw";
    height: "100vh";
} */
.EventsWholePage {
    /* scroll-snap-align: start;
    scroll-snap-stop: always; */
    margin-top: 30vh;

}

.Images {
    display: flex;
    flex-direction: row;
    gap: "5px";
}