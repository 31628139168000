.details-contain{
    max-width: 1200px;
    margin: 0 auto;
}
.details-box{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.details-background{
    border: 2px solid #008080;
    border-radius: 10px;
    background: linear-gradient(#00808060, #00808010);
}

.detailsbox-heading{
    text-align: center;
    font-family: "xspace";
    color: white;
    font-size: 3.2rem;
    margin: 0;
}

.detailsbox-text{
    --font-min: 14px; 
    --font-max: 27px;

    

    height: 100%;

    flex: 1.5;
    text-align: justify;
    color: white;

    margin-left: 5%;
    margin-right: 1%;
    margin-bottom: 2%;
}

@media screen and (max-width: 679px ) {

    .detailsbox-text {
        font-size: var(--font-min);
        line-height: normal;
    }
    .detailsbox-heading{
        font-size: 2.5rem;
    }
}

@media screen and (min-width: 1201px) {
    .detailsbox-text {
        font-size: var(--font-max);
        line-height: 1.3;
    }
    .detailsbox-heading{
        font-size: 4rem;
    }
}