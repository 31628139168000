.FormCol {
    align-items: stretch;
}

@import url('https://fonts.googleapis.com/css2?family=Genos&display=swap');

.EmailForm {
    background-image: linear-gradient(#008080, #00808090);
    height: auto;
    width: auto;
    display: flex;
    flex-direction: column;
    -ms-flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-bottom: 6vh;
    padding-inline: 10vw;
    border-radius: 12.5px;
    border-style: solid;
    border-color: #008080;
    border-width: 2px;
    font-family: 'Genos';
    
}

.flexboxcontainer{
    display: flex;
    flex-direction: column;
    justify-content: center;

    margin: 0 auto;
}

.FormHeading {
    margin-inline: auto;
}

.formtext {
    display: block;
    padding: 0.375rem 0.75rem;
    font-size: 1.4vmax;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.375rem;
    font-family: 'Genos';
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    align-self: left;
}

@media screen and (max-width: 426px) {
    .EmailForm {
        margin-top: 10vh;
    }

    .formtext {
        width: 70vw;
    }

    .SubButton {
        width: 70vw;
    }
}

@media screen and (min-width: 426px) {
    .EmailForm {
        margin-top: 0vh;
    }

    .formtext {
        width: 45vw;
    }

    .SubButton {
        width: 45vw;
    }
}

.FormEmail,
.FormName,
.FormCompany,
.FormPhone,
.FormMessage {
    color: aliceblue;
    display: flex;
    flex-direction: column;
    padding-bottom: 1.25vh;
    padding-inline: 1vw;
}

.FormMessage {
    overflow-y: auto;
}

.FormSubmit {
    padding-top: 3vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    -ms-flex-direction: row;
    justify-content: flex-end;
}

.FormCol {
    display: flex;
    flex-direction: column;
    -ms-flex-direction: column;
    justify-content: space-evenly;
}
label{
    text-align: left; /*this was the issue with the alignment of the label in email box. fixed it this way. else the sponsorswrappers class css was being applied*/
}