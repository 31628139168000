#TimelineContainer {
    width: 100vw;
    display: flex;
    justify-content: center;
}
.timeline-imagebox{
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; */
    display: grid;
    width: 90%;
    height: auto;
    margin: 0 auto;
}
.images-timeline{
    width: 25%;
}
/*.timeline-image{
    width: 100%;
    height: auto;
}*/
.timeline-background{
    /* height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    position: absolute;
    z-index: 1; */
    background-color: black;
}
.timeline-image-topbackground{
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: auto;
    margin: 0 auto;

}
.timeline-reverse-background{
    width: 100%;
    height: auto;
}
/*.timeline-image-margin{
    margin-left: 40px;
}*/
/*@media screen and (max-width: 539px){
    .timeline-image-margin{
        margin-left: 13px;
    }
}

@media screen and (max-width: 911px){
    .timeline-image-margin{
        margin-left: 20px;
    }
}
@media screen and (max-width: 539px){
    .timeline-image-margin{
        margin-left: 13px;
    }
}*/
.class1{
    /* background-color: blue; */
    background: blue;
}
.class2{
    background-color: blue;
}
.class3{
    background-color: blue;
}
.timeline-grid{display:flex;flex-direction:row;}
.first,.second{flex-grow:4;width:90%;}
#image{max-width:100%;padding-bottom:65%;padding-top:110%;}
#image1{max-width:100%;padding-bottom:60%;}
#image2{max-width:100%;padding-bottom:70%;}
#image3{max-width:100%;padding-bottom:75%;}
#image5{max-width:100%;padding-bottom:65%;padding-top:150%;}
#image6{max-width:100%;padding-bottom:57%;}
#image7{max-width:100%;padding-bottom:70%;}
#image8{max-width:100%;padding-bottom:70%;}
#image4,#image9{max-width:100%;}
#first{width:100%;padding-top:110%;max-width:120%;}
#middle{width:175%;padding-right:5%;padding-left:5%}
#second{width:100%;padding-top:145%;max-width:120%;}
#vector{max-width:100%;}
@media screen and (max-width:768px){ 
    #vector{
        max-width:100%; /* Reducing the width of the vector image for smaller screens */
    }
    .first{width:0%;}
    .second{width:0%;}
}